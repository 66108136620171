import React from "react"

const Friday = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 15 15">
    <path
      stroke="#63ab3f"
      d="M7 0h2m4 0h1M8 1h3m1 0h1m-3 1h2M8 3h3M7 4h3M7 5h1"
    />
    <path
      stroke="#3b7d4f"
      d="M14 0h1m-2 1h1M9 2h1m2 0h1m-2 1h1m-1 1h2m-2 1h3m-2 1h2m-1 1h1"
    />
    <path
      stroke="#ad2f45"
      d="M6 1h2M4 2h2m2 0h1M3 3h1M3 4h1m6 0h1M2 5h1m5 0h3M2 6h1m4 0h1m2 0h2M1 7h1m9 0h2M1 8h1m9 0h2M0 9h1m9 0h1m1 0h1M0 10h1m8 0h4M0 11h2m5 0h3m1 0h1M1 12h6m1 0h1m-6 1h3"
    />
    <path
      stroke="#e64539"
      d="M6 2h2M4 3h2m1 0h1M5 4h2M3 5h4M3 6h2m1 0h1m1 0h2M2 7h5m1 0h3M2 8h2m1 0h4m1 0h1M1 9h1m1 0h7m-9 1h5m1 0h2m-7 1h2m1 0h2"
    />
    <path
      stroke="#ffae70"
      d="M6 3h1M4 4h1m0 2h1m1 1h1M4 8h1m4 0h1M2 9h1m3 1h1m-3 1h1"
    />
    <path stroke="#2f5753" d="M12 3h2m-1 1h2m-1 1h1" />
    <path
      stroke="#781d4f"
      d="M13 8h1m-1 1h1m-1 1h1m-2 1h1M0 12h1m8 0h3M0 13h2m4 0h3m-8 1h5"
    />
    <path stroke="#cf752b" d="M11 9h1m-2 2h1m-4 1h1m-6 1h1" />
  </svg>
)

export default Friday
