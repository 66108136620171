import * as React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import { getScores } from "../utils/score"

import {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
} from "../components/icons/week"

import { format, parseISO, isSameWeek } from "date-fns"
import { ca } from "date-fns/locale"

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const toDate = d =>
  capitalize(
    format(parseISO(d, new Date()), "eeee, dd", {
      locale: ca,
    })
  )

const IndexPage = ({
  data: {
    allQuestionDates: { edges },
  },
  pageContext: { week },
}) => {
  const dates = edges
    .map(({ node }) => node)
    .sort()
    .filter(node =>
      isSameWeek(
        week.actual ? parseISO(week.actual) : new Date(),
        parseISO(node.published),
        {
          weekStartsOn: 1,
        }
      )
    )

  return (
    <>
      <Seo title="Home" />
      <h1 className="text-2xl text-center mb-8 bg-blue-50 p-4">{`Setmana del ${format(
        parseISO(week.actual),
        "PPP",
        { locale: ca }
      ).replace(" 2022", "")}`}</h1>
      <div className="flex flex-col w-full">
        {dates.map((date, index) => (
          <Link
            key={index}
            className="w-full justify-between mb-2 border border-white  hover:shadow-xl py-2 group cursor-pointer px-2 hover:bg-yellow-50 hover:border-yellow-100 hover:scale-110 transition text-base group-hover:text-orange-700 flex items-center group pb-2"
            to={`/valencia/${date.published}/${date.questionIds[0]}`}
          >
            <div className="h-10 w-10 mr-8 flex-0 items-center flex transition group-hover:scale-125">
              {format(parseISO(date.published), "i", new Date()) === "1" ? (
                <Monday />
              ) : format(parseISO(date.published), "i", new Date()) === "2" ? (
                <Tuesday />
              ) : format(parseISO(date.published), "i", new Date()) === "3" ? (
                <Wednesday />
              ) : format(parseISO(date.published), "i", new Date()) === "4" ? (
                <Thursday />
              ) : format(parseISO(date.published), "i", new Date()) === "5" ? (
                <Friday />
              ) : format(parseISO(date.published), "i", new Date()) === "6" ? (
                <Saturday />
              ) : (
                <Sunday />
              )}
            </div>
            <div className="transition transform group-hover:scale-110 flex-1">
              {toDate(date.published)}
            </div>
            <div className="text-sm flex flex-0 items-center text-indigo-800 mb-2 pt-2">
              {getScores(date.published).map((score, i) => (
                <div
                  key={i}
                  className={`${
                    score < 50
                      ? "bg-red-400 text-red-800"
                      : score < 70
                      ? "bg-orange-400 text-orange-800"
                      : "bg-green-400 text-green-800"
                  } ml-4 p-px px-2 text-center rounded block text-xl flex items-center`}
                >
                  {parseInt(score)}
                </div>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}

export const query = graphql`
  query allQuestionDates {
    allQuestionDates {
      edges {
        node {
          published
          questionIds
        }
      }
    }
  }
`

export default IndexPage
