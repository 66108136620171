export const getResponses = date => {
  if (typeof window === "undefined") {
    return []
  }

  const responses = JSON.parse(localStorage.getItem(`responses_${date}`)) || {}

  return Object.keys(responses).map(id => ({ ...responses[id], id }))
}

export const getScores = date => {
  if (typeof window === "undefined") {
    return []
  }
  const r = JSON.parse(localStorage.getItem(`score_${date}`)) || []

  return r.reverse().slice(0, 1)
}

export const getScore = date => {
  if (typeof window === "undefined") {
    return 0
  }

  const responses = JSON.parse(localStorage.getItem(`responses_${date}`)) || {}
  const total = Object.keys(responses).length

  if (total === 0) return total

  const num = Object.values(responses).reduce((prev, val) => {
    let score = val.failed ? -1 : 0

    if (val.success === true && val.failed === true) {
      score += 0.5
    } else if (val.success === true) {
      score += 1
    }
    return prev + score > 0 ? prev + score : 0
  }, 0)

  return ((num * 100) / total).toFixed(1)
}
