import React from "react"

const Sunday = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 9">
    <path
      stroke="#63ab3f"
      d="M0 0h1m14 0h1M0 1h1m14 0h1M0 2h1m14 0h1M1 3h1m12 0h1M1 4h2m10 0h2M2 5h1m10 0h1M3 6h2m6 0h2M6 7h4"
    />
    <path
      stroke="#cc2f7b"
      d="M1 0h1m12 0h1M1 1h2m10 0h2M1 2h2m10 0h2M2 3h3m6 0h3M3 4h1m1 0h2m2 0h2m1 0h1M3 5h3m1 0h2m1 0h3M5 6h6"
    />
    <path
      stroke="#ff5277"
      d="M2 0h2m2 0h1m1 0h1m2 0h1m1 0h1M3 1h10M4 2h8M5 3h6M7 4h2"
    />
    <path stroke="#ffc2a1" d="M4 0h2m1 0h1m1 0h2m1 0h1" />
    <path stroke="#3d2936" d="M3 2h1m8 0h1M4 4h1m6 0h1M6 5h1m2 0h1" />
    <path
      stroke="#3b7d4f"
      d="M0 3h1m14 0h1M0 4h1m14 0h1M1 5h1m12 0h1M2 6h1m10 0h1M3 7h3m4 0h3M6 8h4"
    />
  </svg>
)

export default Sunday
