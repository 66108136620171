import React from "react"

const Wednesday = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 11 11">
    <path stroke="#573524" d="M7 0h1M6 1h1M5 2h1M5 3h1" />
    <path
      stroke="#4a9426"
      d="M3 3h2M3 4h1M2 5h2M2 6h3M3 7h3m3 0h1M4 8h3m1 0h1M5 9h3"
    />
    <path stroke="#61bb37" d="M6 3h2M4 4h4M4 5h2m2 0h1M5 6h4M6 7h3M7 8h1" />
    <path
      stroke="#476f33"
      d="M2 4h1M1 5h1M1 6h1M1 7h2M1 8h3m5 0h1M2 9h3m3 0h1m-5 1h4"
    />
    <path stroke="#70e730" d="M8 4h1M6 5h2m1 0h1M9 6h1" />
  </svg>
)

export default Wednesday
