import React from "react"

const Monday = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 14">
    <path
      stroke="#7d3833"
      d="M5 0h6M3 1h2m6 0h2M2 2h1M1 3h1M0 4h1M0 5h1M0 6h1M0 7h1m1 0h1m10 0h2M1 8h1m1 0h1m7 0h1m1 0h1M1 9h1m2 0h1m1 0h1m1 0h2m1 0h3M2 10h1m1 0h1m3 0h2m1 0h2M3 11h1m1 0h1m1 0h5m-8 1h2m1 0h3m-4 1h1"
    />
    <path
      stroke="#f5ffe8"
      d="M5 1h6M3 2h2m3 0h2m1 0h2M2 3h1m6 0h3m1 0h1M1 4h1m8 0h3m1 0h1M1 5h1m8 0h3m1 0h1M2 6h1m6 0h3m1 0h1M3 7h2m3 0h2m1 0h2M5 8h6"
    />
    <path stroke="#a3a7c2" d="M5 2h2M3 3h3M2 4h3M2 5h3M3 6h3M5 7h2" />
    <path
      stroke="#dfe0e8"
      d="M7 2h1m2 0h1M6 3h3m3 0h1M5 4h5m3 0h1M5 5h5m3 0h1M6 6h3m3 0h1M7 7h1m2 0h1"
    />
    <path
      stroke="#3b2027"
      d="M13 2h1m0 1h1m0 1h1m-1 1h1m-2 1h2m-1 1h1m-4 1h1m1 0h1m-5 1h1m3 0h1m-5 1h1m2 0h1m-2 1h1m-3 1h2m-5 1h3"
    />
    <path
      stroke="#ab5130"
      d="M1 6h1M1 7h1m0 1h1m1 0h1M2 9h2m1 0h1m1 0h1m-5 1h1m1 0h3m-4 1h1m1 0h1m-1 1h1"
    />
  </svg>
)

export default Monday
