import React from "react"

const Tuesday = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 16">
    <path
      stroke="#7d3833"
      d="M12 0h2m-2 1h3m-2 1h2m-1 1h1M2 5h1M1 6h2M0 9h2m-2 1h1m1 2h2m-1 1h1"
    />
    <path
      stroke="#f0b541"
      d="M12 2h1m-2 1h2M8 4h3m1 0h1m1 0h1M3 5h5m2 0h1m1 0h1m1 0h1M8 6h2m1 0h1m2 0h1M6 7h2m3 0h1m1 0h2M3 8h3m3 0h2m2 0h2M8 9h2m3 0h1m-8 1h3m3 0h2M2 11h5m4 0h2m-3 1h3m-9 1h8m-5 1h3"
    />
    <path
      stroke="#ffee83"
      d="M13 3h1m-3 1h1m1 0h1M8 5h2m1 0h1m1 0h1M3 6h5m2 0h1m1 0h2M2 7h4m2 0h3m1 0h1M6 8h3m2 0h2M2 9h6m2 0h3M1 10h5m3 0h3m-5 1h4m-7 1h6"
    />
    <path
      stroke="#cf752b"
      d="M15 4h1m-1 1h1m-1 1h1M1 7h1m13 0h1M2 8h1m12 0h1m-2 1h1m-1 1h1M1 11h1m11 0h1m-1 1h1m-2 1h1m-9 1h3m3 0h2m-5 1h3"
    />
  </svg>
)

export default Tuesday
