import React from "react"

const Thursday = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 16">
    <path stroke="#ab5130" d="M14 0h2m-3 1h1M6 7h2M5 8h2" />
    <path
      stroke="#63ab3f"
      d="M10 1h3M8 2h2M5 3h3M3 4h2m8 0h1M2 5h1m10 0h1M1 6h1m10 0h1M0 7h1m11 0h1M0 8h1m7 0h1m2 0h1M4 9h1m2 0h1m2 0h1M1 10h1m3 0h2m2 0h1m-8 1h1m5 0h2m-7 1h5"
    />
    <path stroke="#7d3833" d="M14 1h2m-2 1h1M8 7h1M7 8h1M5 9h2" />
    <path
      stroke="#c8d45d"
      d="M10 2h4M8 3h2m2 0h2M5 4h2m4 0h2M3 5h2m4 0h4M2 6h1m5 0h4M1 7h1m7 0h3M1 8h1m1 0h1m5 0h2M1 9h3m4 0h2m-8 1h3m2 0h2m-6 1h5"
    />
    <path stroke="#ffee83" d="M10 3h2M7 4h4M5 5h4M3 6h3M2 7h3M2 8h1" />
    <path
      stroke="#3b7d4f"
      d="M14 3h1m-1 1h1m-1 1h1m-2 1h1m-1 1h1m-2 1h1M0 9h1m10 0h1M0 10h1m9 0h1M1 11h1m8 0h1m-9 1h1m5 0h2m-7 1h5"
    />
    <path stroke="#cf752b" d="M6 6h2M5 7h1M4 8h1" />
    <path
      stroke="#283540"
      d="M14 6h1m-2 2h1m-2 1h1m-2 1h2M0 11h1m10 0h1M0 12h2m8 0h2M1 13h2m5 0h3m-9 1h8m-7 1h6"
    />
  </svg>
)

export default Thursday
