import React from "react"

const Saturday = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 16">
    <path
      stroke="#63ab3f"
      d="M8 0h2M7 1h1m1 0h1m0 1h1M6 3h1m3 0h1M5 4h1m4 0h1M5 5h1m4 0h1m-1 1h1m-1 1h1"
    />
    <path
      stroke="#3b7d4f"
      d="M8 1h1M7 2h1m1 0h1M7 3h1M6 4h1M6 5h1M6 6h1m4 0h1m-1 1h1"
    />
    <path
      stroke="#ad2f45"
      d="M2 5h3M1 6h1m3 0h1m1 0h1M0 7h1m5 0h1m2 0h1m2 0h2M0 8h1m5 0h1m3 0h1m3 0h1M0 9h1m4 0h1m8 0h1M0 10h2m2 0h2m7 0h2M2 11h3m1 0h1m6 0h1m-8 1h3m3 0h2m-6 1h5m-4 1h3"
    />
    <path stroke="#ffc2a1" d="M2 6h1M1 8h1m6 0h1M7 9h1m-1 1h1" />
    <path
      stroke="#e64539"
      d="M3 6h2M1 7h5m2 0h1M2 8h4m1 0h1m1 0h1m1 0h3M1 9h4m1 0h1m1 0h6M2 10h2m2 0h1m1 0h5m-6 1h6m-4 1h3"
    />
    <path
      stroke="#781d4f"
      d="M7 7h1m7 2h1m-1 1h1M1 11h1m3 0h1m8 0h2M2 12h4m8 0h2m-9 1h1m5 0h2m-7 1h1m3 0h2m-5 1h4"
    />
  </svg>
)

export default Saturday
